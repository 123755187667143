<template>
  <div>
    <p>建置中</p>
  </div>
</template>

<script>
export default {
  name: 'Error',
  data() {
    return {

    };
  },
  methods: {

  },
};
</script>
